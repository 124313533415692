import './index.html';
import './style.scss';
import {imageLazyLoading} from "../../scripts/functions/imageLazyLoading";
import {animations} from "../../scripts/general/animations";
import {vimeoPlayer} from "../../scripts/general/vimeo-player";
import {youtubePlayer} from "../../scripts/general/youtube-player";

/**
 *
 * @param block {HTMLElement}
 * @returns {Promise<void>}
 */
const heroBlock = async (block) => {
  const videoWrapper = block.querySelector(".video-wrapper");
  const videoCont = block.querySelector(".video-iframe");
  const video = block.querySelector(".video-player");
  
  if (videoWrapper && videoCont) {
    const videoType = videoCont.dataset.videoType;
    const videoURL = videoCont.dataset.videoUrl;
    let player;
    if (videoType === "youtube") {
      youtubePlayer(video, videoURL, {annotations: false, related: false});
    } else if (videoType === "vimeo") {
      vimeoPlayer(video, videoURL);
    }
  }
  
  animations(block);
  imageLazyLoading(block);
};

export default heroBlock;

