import './index.html';
import './style.scss';
import {imageLazyLoading} from '../../scripts/functions/imageLazyLoading';
import {animations} from '../../scripts/general/animations';
import {gsap} from "gsap";

/**
 *
 * @param block {HTMLElement}
 * @returns {Promise<void>}
 */
export default async (header) => {
  const burgerMenu = header.querySelector('.burger-menu');
  const menuLinks = header.querySelector('.center-header');
  
  
  if (!burgerMenu) return;
  const burgerTl = gsap.timeline({paused: true});
  const burgerSpans = burgerMenu.querySelectorAll('span');
  gsap.set(burgerSpans, {transformOrigin: 'center'});
  burgerTl
      .to(burgerSpans, {
        y: gsap.utils.wrap([`1.2rem`, 0, `-1.2rem`]),
        duration: 0.25,
      })
      // .to(header.querySelector(".burger-menu span:first-child"), {
      //   marginBottom: -1,
      //   duration: .3,
      //   ease: "power3.inOut"
      // }, "<")
      .set(burgerSpans, {autoAlpha: gsap.utils.wrap([1, 0, 1])})
      .to(burgerSpans, {rotation: gsap.utils.wrap([45, 0, -45])})
      .set(burgerSpans, {rotation: gsap.utils.wrap([45, 0, -45])})
  
  burgerMenu.addEventListener('click', function () {
    
    if (burgerMenu.classList.contains('burger-menu-active')) {
      // region allow page scroll
      document.documentElement.classList.remove('modal-opened');
      // endregion allow page scroll
      burgerMenu.classList.remove('burger-menu-active');
      menuLinks.classList.remove('header-links-active');
      header.classList.remove('header-active');
      const activeItem = header.querySelector('.menu-item-has-children.menu-item-active');
      activeItem && activeItem.classList.remove('menu-item-active');
      burgerTl.reverse();
    } else {
      burgerMenu.classList.add('burger-menu-active');
      menuLinks.classList.add('header-links-active');
      header.classList.add('header-active');
      burgerTl.play();
      // region prevent page scroll
      document.documentElement.classList.add('modal-opened');
      // endregion prevent page scroll
      gsap.fromTo(menuLinks.querySelectorAll('.menu-item ,.banner-link, .right-wrapper'), {
        x: -30,
        autoAlpha: 0,
      }, {
        x: 0,
        autoAlpha: 1,
        stagger: .05,
        duration: .4,
        delay: .5,
      });
    }
  });
  
  
  const headerLinks = header.querySelectorAll('.primary-menu .has-megaMenu');
  
  headerLinks.forEach((menuItem) => {
    
    menuItem?.addEventListener('click', (e) => {
      
      const isOpened = menuItem?.classList.toggle('active-header-link');
      
      const menuItemBody = menuItem.querySelector('.sub-menu');
      if (!isOpened) {
        gsap.to(menuItemBody, {height: 0});
      } else {
        gsap.to(Array.from(headerLinks).map(otherMenuItem => {
          const otherMenuItemBody = otherMenuItem.querySelector('.sub-menu');
          if (otherMenuItemBody && menuItem !== otherMenuItem) {
            otherMenuItem?.classList.remove('active-header-link');
            gsap.set(otherMenuItem, {zIndex: 1});
          }
          return otherMenuItemBody;
          
        }), {height: 0});
        gsap.set(menuItem, {zIndex: 2});
        gsap.to(menuItemBody, {height: 'auto'});
      }
    });
  });
  
  
  // const sticky = "sticky";
  // const scrollUp = 'scroll-up';
  // const hasBanner = 'has-banner';
  //
  // let lastScroll = 0;
  // window.addEventListener("scroll", () => {
  //   const currentScroll = window.scrollY;
  //   if (currentScroll <= 50) {
  //     header.classList.add(scrollUp)
  //     header.classList.add(hasBanner)
  //     header.classList.remove(sticky);
  //   } else {
  //     header.classList.remove(scrollUp);
  //     header.classList.remove(hasBanner);
  //     header.classList.toggle(sticky, currentScroll > lastScroll);
  //   }
  //   lastScroll = currentScroll;
  // });
  
  
  animations(header);
  imageLazyLoading(header);
};

